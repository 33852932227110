<template>
  <div class="col-12 g col-lg-6 c">
    <div class="card card-body">
        <div class="row">
            <div class="col-12 table-responsive c">
                <div class="form-group">
                    <h5 for="">بحث</h5>
                    <input type="text"
                    class="form-control" v-model="q" placeholder="ابحث هنا..." @keyup="getStudents()" @change="getStudents()">
                </div>
                <table class="table table-sm table-hover table-bordered table-striped">
                    <thead>
                        <th>
                            الاسم
                        </th>
                        <th>
                            الهوية
                        </th>
                        <th>
                            الرصيد
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="student in students" :key="student._id" @click="current = student; getHistory(student.number)" v-b-modal.modal-1>
                            <td>
                                {{ student.name }}
                            </td>
                            <td>
                                {{ student.number }}
                            </td>
                            <td>
                                {{ student.balance }} ريال
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <b-modal id="modal-1" title="الملف الشخصي" hide-footer size="lg">
        <div v-if="current.name">
            <div class="col-12 text-center">
                <h1>متبقي <strong class="text-danger">{{ current.balance }}</strong> ريال </h1>
                <h3>
                    {{ current.name }}
                </h3>
                <p class="text-muted">{{ current.number }} &mdash; {{ current.phone }}</p>
                <button class="btn btn-primary" v-b-modal.modal-2 @click="charge = {}"><i class="fa fa-plus"></i> شحن رصيد الآن</button>
                <br>
                <br>
            </div>
            <div class="col-12 text-center g" v-if="loading">
                <img :src="require('@/assets/images/loading.svg')" alt="">
            </div>
            <div class="col-12" v-if="!loading">
                <div class="strike">
                    <span>سجل شحن الرصيد</span>
                </div>
                <div class="col-12 table-responsive g">
                    <table class="table table-sm table-bordered">
                        <thead>
                            <th>
                                التاريخ
                            </th>
                            <th>
                                المبلغ
                            </th>
                            <th>
                                الرصيد قبل
                            </th>
                            <th>
                                الرصيد بعد
                            </th>
                            <th>
                                ملاحظات
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="c in charges" :key="c._id">
                                <td>{{ c.date }}</td>
                                <td>{{ c.amount }}</td>
                                <td>{{ c.balance_before }}</td>
                                <td>{{ c.balance_after }}</td>
                                <td>{{ c.notes }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="strike">
                    <span>سجل السحب</span>
                </div>
                <div class="col-12 table-responsive g">
                    <table class="table table-sm table-bordered">
                        <thead>
                            <th>
                                التاريخ
                            </th>
                            <th>
                                المبلغ
                            </th>
                            <th>
                                الرصيد قبل
                            </th>
                            <th>
                                الرصيد بعد
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="c in history" :key="c._id">
                                <td>{{ c.date }}</td>
                                <td>{{ c.amount }}</td>
                                <td>{{ c.balance_before }}</td>
                                <td>{{ c.balance_after }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </b-modal>
    <b-modal id="modal-2" title="شحن رصيد" hide-footer>
        <div class="form-group">
          <label for="">المبلغ <span class="text-danger">*</span></label>
          <input type="number"
            class="form-control" v-model="charge.amount">
        </div>
        <div class="form-group">
          <label for="">ملاحظات</label>
          <input type="text"
            class="form-control" v-model="charge.notes">
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="chargeNow()" v-if="!loading2">
                شحن الرصيد الآن
            </button>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
    data(){
        return {
            students: [],
            jwt: JSON.parse(localStorage.getItem("user")).jwt,
            q: null,
            current: {},
            charges: [],
            history: [],
            loading: false,
            loading2: false,
            charge: {}
        }
    },
    created(){
        var g = this;
        g.getStudents()
    },
    methods: {
        getHistory(){
            var g = this;
            this.loading = true
            $.post(api + '/user/students/history', {
                jwt: this.jwt,
                number: this.current.number
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.history = r.response.history
                    g.charges = r.response.charges
                }
            }).fail(function(){
                g.loading = false
                alert("حدث مشكلة في الاتصال")
            })
        },
        getStudents(){
            var g = this;
            $.post(api + '/user/students/list', {
                jwt: this.jwt,
                q: g.q
            }).then(function(r){
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.students = r.response
                }
            }).fail(function(){
                alert("حدث مشكلة في الاتصال")
            })
        },
        chargeNow(){
            var g = this;
            if(g.charge.amount){
                g.loading2 = true
                $.post(api + '/user/students/charge', {
                    jwt: this.jwt,
                    amount: this.charge.amount,
                    notes: this?.charge?.notes,
                    number: this.current.number
                }).then(function(r){
                    g.loading2 = false
                    if(r.status != 100){
                        alert(r.response)
                    }else{
                        $("#modal-2___BV_modal_header_ > button").click()
                        setTimeout(() => {
                            $("#modal-1___BV_modal_header_ > button").click()
                            g.q = g.current.number
                            g.getStudents()
                        }, 300);
                    }
                }).fail(function(){
                    g.loading2 = false
                    alert("حدث مشكلة في الاتصال")
                })
            }
        }
    }
}
</script>

<style scoped>
.table-striped tr{
    transition: transform .2s;
}
.table-striped tr:hover{
    transform: scale(1.05);
    background: #EBEF95 !important;
}
.strike {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap; 
}

.strike > span {
    position: relative;
    display: inline-block;
}

.strike > span:before,
.strike > span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: #eee;
}

.strike > span:before {
    right: 100%;
    margin-right: 15px;
}

.strike > span:after {
    left: 100%;
    margin-left: 15px;
}
</style>